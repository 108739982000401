<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="22"
    viewBox="0 0 12 22"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.37964 11.622C0.885828 12.1262 1.70193 12.1262 2.20812 11.622L11.6242 2.20436C12.1253 1.70025 12.1253 0.883116 11.6242 0.378478C11.118 -0.126159 10.3019 -0.126159 9.79576 0.378478L0.37964 9.79564C-0.126547 10.2998 -0.126547 11.1174 0.37964 11.622ZM11.6242 21.3156C12.1253 20.8114 12.1253 19.9943 11.6242 19.4896L5.44152 13.3095C4.4343 12.3013 2.79694 12.3013 1.78973 13.3095L9.79576 21.3156C10.3019 21.8202 11.118 21.8202 11.6242 21.3156Z"
      fill="white"
    />
  </svg>
</template>
