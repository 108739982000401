<template>
  <NuxtLink
    class="rounded-md p-2 border-2 items-start flex flex-col gap-y-2 transition-border-color 0.3s ease-in-out"
    @mouseover="activate"
    @mouseleave="deactivate"
    :class="isActive ? 'border-[#EA57A8]' : 'border-[#F5F5F5]'"
    :to="sanitizeLink(link)"
    @click="handleStrainClick(redirectLink)"
    rel="noopener noreferrer"
  >
    <div
      class="flex justify-between items-center w-full"
      v-if="icon"
    >
      <h6
        class="text-base font-alt text-start text-black-900 font-medium"
        :class="isActive ? 'text-[#EA57A8]' : 'text-black-900'"
      >
        {{ title }}
      </h6>
      <img
        v-if="title === 'Sativa'"
        src="@/assets/icon/png/sativa.png"
        :alt="title"
        class="h-15 w-15"
      />
      <img
        v-if="title === 'Hybrid'"
        src="@/assets/icon/png/hybrid.png"
        :alt="title"
        class="h-15 w-15"
      />
      <img
        v-if="title === 'Indica'"
        src="@/assets/icon/png/indica.png"
        :alt="title"
        class="h-15 w-15"
      />
    </div>
    <h6
      v-else
      class="text-base font-alt text-start text-black-900 font-medium"
      :class="isActive ? 'text-[#EA57A8]' : 'text-black-900'"
    >
      {{ title }}
    </h6>
    <p class="pt-2 font-alt text-xs text-black-900 font-medium">
      {{ text }}
    </p>
  </NuxtLink>
</template>

<script setup>
  const props = defineProps({
    title: String,
    text: String,
    icon: String,
    link: String,
    redirectLink: String
  })

  const emit = defineEmits(['hyperlink'])

  const isActive = ref(false)

  const activate = () => {
    isActive.value = true
  }

  const deactivate = () => {
    isActive.value = false
  }

  const handleStrainClick = (redirectLink) => {
    emit('hyperlink', redirectLink)
    if (window.innerWidth >= 1024) {
      document.getElementsByClassName('s-dropdown-menu')[0].style.top = '-1000px'
      setTimeout(() => {
        const dropdownMenu = document.getElementsByClassName('s-dropdown-menu')[0]
        if (dropdownMenu) {
          dropdownMenu.style.removeProperty('top')
        }
      }, 100)
    }
  }

  const sanitizeLink = (link) => {
    let linkPath = typeof link === 'string' ? link : link.path || ''

    // Ensure the link starts with a "/" for consistent path handling
    if (!linkPath.startsWith('/')) {
      linkPath = '/' + linkPath
    }

    // Remove "/product" if it exists
    if (linkPath.includes('/product')) {
      linkPath = linkPath.replace('/product', '')
    }

    return linkPath
  }
</script>
