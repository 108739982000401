<template>
    <div class="lg:mt-2 w-full h-full flex flex-col">
        <h5 class="font-alt text-sm mt-4 text-[#404040] hidden lg:block">
            Explore categories below to see what&apos;s in stock. Fine-tune your search on the category pages, and
            don&apos;t
            forget—select your location for real-time availability!
        </h5>
        <div class="lg:hidden flex justify-start gap-x-2 items-center border-b-2 border-[#D9D9D9] py-5 px-6">
            <button class="outline-none border-none" @click="emit('close')">
                <IconCaretLeft class="w-4 h-4 mr-4" />
            </button>
            <h5 class="font-main text-[#F3B6D0] uppercase font-bold text-text-start font-alt text-xl"
                @click="emit('close')">
                Brands
            </h5>
        </div>
        <div
            class="mt-4 flex space-y-4 justify-center lg:space-y-2 items-center flex-wrap px-[24px] lg:px-0 dynamic-height">
            <NuxtLink v-for="brand in headerData.weed_brands" :key="brand.weed_brand_object"
                :to="returnBrandLink(brand.weed_brand_object)" rel="noopener noreferrer"
                class="hover:border-[#EA57A8] border border-transparent rounded-md overflow-hidden group transition duration-300 ease-in-out p-2 w-auto"
                @click="handleBrandClick(brand.weed_brand_object)">
                <NuxtImg :src="$correctedImageUrl(brand.weed_brand_image).cleanUrl" format="webp" :modifiers="{ q: 75 }"
                    loading="lazy" :alt="brand.weed_brand_object"
                    class="h-auto transform transition-transform duration-300 ease-in-out lg:w-auto w-24 lg:max-w-[110px]" />
            </NuxtLink>
        </div>
        <MegaMenuMobileBackFooter @click="emit('close')" />
    </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { getBrand, extractAndFormat, replaceSuppliersWithBrands, getAbsolutePath } from '@/components/mega-menu/helpers'

const store = useStore()
const headerData = store.getters.GET_HEADER_DATA
const product = store.getters['shop/xShopControls']
const emit = defineEmits(['close', 'hyperlink'])

const handleBrandClick = (brandObject) => {
    emit('hyperlink', getBrand(brandObject))
    if (window.innerWidth >= 1024) {
        document.getElementsByClassName('s-dropdown-menu')[0].style.top = '-1000px'
        setTimeout(() => {
            const dropdownMenu = document.getElementsByClassName('s-dropdown-menu')[0]
            if (dropdownMenu) {
                dropdownMenu.style.removeProperty('top')
            }
        }, 100)
    }
}

function handleClearActions(clearQuery) {
    // Temporary fix for clearing filters - we need to expand how menu works + use Vue emitters. Out of scope for now.
    emit('close')
    document.querySelector('.s-shop-cleat-filter-btn').click()
    if (typeof clearQuery === 'function') {
        clearQuery()
    }
}

function returnBrandLink(brand) {
    return getAbsolutePath(replaceSuppliersWithBrands(brand));
}
</script>

<style scoped>
.menu-hidden:hover {
    top: -1000px !important;
}
</style>
