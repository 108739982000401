<template>
    <NuxtLink to="https://merch.theflowery.co/" target="_blank" rel="noopener noreferrer" :external="true"
        class="h-21 w-full flex justify-between text-lg font-alt font-medium bg-white py-6 px-8 items-center">
        Merch &amp; Accessories

        <MegaMenuIconLink />
    </NuxtLink>


</template>

<script setup></script>
