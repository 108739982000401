<template>
    <div class="fixed bottom-0 left-0 h-20 px-4 bg-white w-full z-[29] sm:hidden mobiloud-menu hidden" :class="{
        'mobile-menu-hidden': isHidden,
        'mobile-menu-visible': !isHidden,
        '': insideMenu
    }" :style="isProductPage ? 'display: none !important' : ''">
        <nav class="flex justify-between items-center w-full relative">
            <MobileMenuButton :inside-menu="insideMenu" @menuToggled="toggleMenu" />
            <MobileBrandButton />
            <RenderlessXCart v-slot="{ xShoppingCart }">
                <NuxtLink :to="'/cart'" class="text-white relative top-[-16px]">
                    <img src="@/assets/icon/svg/mobile-cart.svg?url" alt="View your shopping cart"
                        class="w-[70px] h-[70px]" />
                    <div
                        class="rounded-full p-0.5 absolute top-4 right-4 min-w-[15px] min-h-[15px] bg-pink leading-none text-center text-xs text-black">
                        {{ xShoppingCart.totalQuantity }}
                    </div>
                </NuxtLink>
            </RenderlessXCart>
            <MobileMenuSpecialButton class="open-chat" />
            <MobileAccountButton />
        </nav>
    </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { ref, computed, watch } from 'vue'
import { useRoute } from 'vue-router'

const store = useStore()
const isOptionSelected = () => store.getters.xIsSelectedOptionsStatus
const xIsNavbarOpen = () => computed(() => store.getters.xIsNavbarOpen)

const isHidden = ref(false)
const route = useRoute()

const isProductPage = computed(() => route.name === 'product-id') // Adjust the name accordingly

defineProps({
    insideMenu: {
        type: Boolean,
        default: false
    }
})

watch(isOptionSelected, (newValue) => {
    isHidden.value = newValue
})

const toggleMenu = () => {
    store.dispatch('xToggleMobileMenu')
}
</script>

<style scoped lang="postcss">
.mobile-menu-hidden {
    transition: all 0.5s ease-in-out 0s;
    transform: translate3d(0, 200%, 0);
}

.mobile-menu-visible {
    transition: all 0.5s ease-in-out 0s;
    transform: translate3d(0, 0%, 0);
}
</style>
