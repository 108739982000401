import { BRANDS_TO_REPLACE, CATEGORIES_TO_REPLACE, BRAND_LINKS } from '@/components/mega-menu/constants'

export function extractAndFormat(url) {
  const afterEquals = url.substring(url.lastIndexOf('=') + 1)
  return afterEquals.replace(/%20/g, ' ')
}

export const replaceSuppliersWithBrands = (str) => {
  const brandName = decodeURIComponent(str).split('=').pop()

  const brandLink = BRANDS_TO_REPLACE[brandName]
  const result = str.replace('suppliers', 'brands')

  if (brandLink) {
    return brandLink
  }
  return result
}

export const getBrand = (str) => {
  const brand = str.replace('/shop?', '').replace('suppliers', 'brands').replace('%20', ' ')

  if (BRAND_LINKS[brand]) {
    return BRAND_LINKS[brand]
  }

  if (brand.includes('%5B%5D')) {
    return brand.replace('%5B%5D', '[]')
  }

  return brand
}

export const getCategoryLink = (locationId, label) => {
  const categoryToUse = CATEGORIES_TO_REPLACE[label] ? CATEGORIES_TO_REPLACE[label] : label
  return `filter_groups[0][categories][]=${categoryToUse}&location_id=${locationId}`
}

export const getSubcategoryLink = (locationId, category, subcategory) => {
  const categoryToUse = CATEGORIES_TO_REPLACE[category] ? CATEGORIES_TO_REPLACE[category] : category
  return `filter_groups[0][categories][]=${categoryToUse}&filter_groups[0][sub_categories][]=${subcategory}&location_id=${locationId}`
}

export const generateShopCategoryLink = (locationId, category, subcategory) => {
  const categoryToUse = CATEGORIES_TO_REPLACE[category] ? CATEGORIES_TO_REPLACE[category] : category

  const defaulLink = `shop?location_id=${locationId}&filter_groups%5B0%5D%5Bcategories%5D%5B%5D=${categoryToUse}`
  if (subcategory) {
    return `${defaulLink}&filter_groups%5B0%5D%5Bsub_categories%5D%5B%5D=${subcategory}`
  }
  return defaulLink
}

export const generateCategoryLink = (link, locationId = '99') => {
  // If this is a full hyperlink just return as is
  if (link.slice(0, 4) === 'http') {
    return link
  }

  // Check if the link contains 'categories'
  if (link.includes('categories')) {
    // Extract the category value
    const categoryMatch = link.match(/categories%5B%5D=([^&]+)/)
    if (categoryMatch && categoryMatch[1]) {
      const category = decodeURIComponent(categoryMatch[1])

      // Handle special cases for Pre-Rolled and Handroll
      if (category === 'Pre-Rolled' || category === 'Handroll') {
        const subCategory = category === 'Pre-Rolled' ? 'Pre-Rolls' : 'Hand-Rolls'
        return `/shop?location_id=${locationId}&filter_groups%5B0%5D%5Bcategories%5D%5B%5D=Flower&filter_groups%5B0%5D%5Bsub_categories%5D%5B%5D=${subCategory}`
      }

      // Construct the new link format with location_id for other categories
      return `/shop?location_id=${locationId}&filter_groups%5B0%5D%5Bcategories%5D%5B%5D=${category}`
    }
  }

  // If it doesn't contain 'categories' or we couldn't extract the category,
  // return the original link as a relative path from shop, still including location_id
  const basePath = link.startsWith('/') ? link : `/${link}`
  return `${basePath}${basePath.includes('?') ? '&' : '?'}location_id=${locationId}`
}

export const getAbsolutePath = (link) => {
  if (link.startsWith('/')) {
    // It's already an absolute path
    return link
  }

  // Make it an absolute path
  return `/${link}`
}
