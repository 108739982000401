<template>
  <button
    type="button"
    class="flex flex-col items-center gap-2 min-w-[67px]"
  >
    <div class="pointer-events-none text-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M17.1406 15.8677C17.5439 14.9554 17.7678 13.9462 17.7678 12.8846C17.7678 8.80621 14.4619 5.5 10.3839 5.5C6.30589 5.5 3 8.80621 3 12.8846C3 16.963 6.30589 20.2692 10.3839 20.2692C11.6969 20.2692 12.9298 19.9265 13.9981 19.3257L19 21.5L17.1406 15.8677Z"
          stroke="#9A9A9A"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <circle
          cx="17.5"
          cy="7"
          r="5.5"
          fill="black"
        />
      </svg>
      <span class="uppercase text-[8px] font-alt font-medium text-[#4F5162]"> Chat </span>
    </div>
  </button>
</template>
<script setup lang="ts"></script>
